import { v4 as uuid } from 'uuid';

const id = uuid();

window.tabId = id;

function setActiveBoxtTabId() {
  if (document.visibilityState === 'visible') {
    localStorage.setItem('activeBoxtTabId', id);
  }
}

function onVisibilityChange() {
  setActiveBoxtTabId();
}

setActiveBoxtTabId();
document.addEventListener('visibilitychange', onVisibilityChange);
